import { compose } from '@reduxjs/toolkit';
import { Button, Col, DatePicker, Input, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import NJVTable from "../../../components/njv-table";
import FetchSearchUrl from '../../../components/fetch-searchUrl';
import { formatDateDigit, formatDateTime } from '../../../components/format';
import { TitleLevel3 } from '../../../components/general-component';
import SearchFilter from '../../../components/searchFilter';
import Api from '../../../network/api';
import withRouter from '../../../network/with-router';
const { RangePicker } = DatePicker

const colorOfLevel = (value) => {
    return value === 'NORMAL' ? '#2db7f5' : value === 'GOLD' ? '#FFD700' : value === 'PLATINUM' ? '#d9d9d9' : null;
}

class LoyaltyUsageHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDownloading: false,
            filterData: {}
        }
    }

    componentDidMount() {
        const { filterData } = FetchSearchUrl()

        let formattedFilterData = { ...filterData };

        if (filterData?.fromDate && filterData?.toDate) {
            formattedFilterData.fromDate = dayjs(filterData?.fromDate)
            formattedFilterData.toDate = dayjs(filterData?.toDate)
        }
        if (filterData['fromDate-toDate']) {
            formattedFilterData['fromDate-toDate'] = [dayjs(filterData?.fromDate), dayjs(filterData?.toDate)];
        }

        this.formattedParmas(filterData)
        this.setState({
            filterData: formattedFilterData
        })
    }

    formattedParmas = (filterData) => {
        let params = {};
        if (filterData) {
            params = {
                'fromDate': (filterData['fromDate-toDate'] && filterData.fromDate) && formatDateDigit(filterData?.fromDate),
                'toDate': (filterData['fromDate-toDate'] && filterData.toDate) && formatDateDigit(filterData?.toDate),
                'fromDate-toDate': filterData['fromDate-toDate'] && `${formatDateDigit(filterData['fromDate-toDate'][0])}&${formatDateDigit(filterData['fromDate-toDate'][1])}`,
                'businessName': filterData?.businessName,
                'shopName': filterData?.shopName
            }
        }
        this.setState({
            params
        })
    }

    search = () => {
        const { filterData } = this.state;

        let formattedFilterData = { ...filterData };
        if (filterData?.fromDate) {
            formattedFilterData.fromDate = formatDateDigit(filterData.fromDate);
        }
        if (filterData?.toDate) {
            formattedFilterData.toDate = formatDateDigit(filterData.toDate);
        }

        if (filterData?.['fromDate-toDate']) {
            formattedFilterData['fromDate-toDate'] = `${formatDateDigit(filterData['fromDate-toDate'][0])}&${formatDateDigit(filterData['fromDate-toDate'][1])}`;
        }
        else {
            if (!filterData.fromDate) {
                formattedFilterData.fromDate = null;
            }
            if (!filterData.toDate) {
                formattedFilterData.toDate = null;
            }
        }

        const newUrl = SearchFilter(formattedFilterData);
        window.history.pushState({}, '', newUrl)
        this.reFetch(1);
    }

    handleFilter = (key, value) => {
        let { filterData } = this.state

        filterData = {
            ...filterData,
            [key]: value
        }

        if (key === 'fromDate-toDate' && value) {
            filterData = { ...filterData, fromDate: value[0], toDate: value[1], 'fromDate-toDate': value }
        } else {
            filterData = { ...filterData, 'fromDate': null, 'toDate': null }
        }

        this.setState({
            filterData
        })
        this.formattedParmas(filterData)
    }

    onDownloadStatusChange = (isDownloading) => {
        this.setState({ isDownloading });
    };

    render() {
        const { filterData, params, isDownloading } = this.state

        const columns = [
            {
                title: 'Business Name',
                key: 'businessName',
                dataIndex: 'businessName',
                render: (_, record) => <>{record?.userInfo?.businessName}</>
            },
            {
                title: 'Shop Name',
                key: 'shopName',
                dataIndex: 'shopName',
                render: (_, record) => <>{record?.loyaltyShop?.fullName}</>
            },
            {
                title: 'Level',
                key: 'loyaltyLevel',
                dataIndex: 'loyaltyLevel',
                align: 'center',
                render: (value) => (
                    <Tag color={colorOfLevel(value)}>{value}</Tag>
                )
            },
            {
                title: 'Date',
                key: 'createdDate',
                dataIndex: 'createdDate',
                align: 'center',
                render: (value) => formatDateTime(value)
            },
        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]}>
                <Col span={12}>
                    <TitleLevel3 label="Loyalty Usage History" />
                </Col>
                <Col span={12} style={{ display: 'flex', justifyContent: 'right' }}>
                    <Button size="large"
                        loading={isDownloading}
                        onClick={() => {
                            this.exportExcel();
                            this.formattedParmas(filterData)
                        }}
                        type="primary" style={{ backgroundColor: 'green' }}>Export Excel</Button>
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
                        <Col span={6}>
                            <Input
                                value={filterData?.businessName}
                                onChange={(e) => this.handleFilter('businessName', e.target.value)}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                placeholder="Business Name"
                                name="businessName"
                                autoComplete='off'
                            />
                        </Col>
                        <Col span={6}>
                            <Input
                                value={filterData?.shopName}
                                onChange={(e) => this.handleFilter('shopName', e.target.value)}
                                style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                                placeholder="Shop Name"
                                name="shopName"
                                autoComplete='off'
                            />
                        </Col>
                        <Col span={6}>
                            <RangePicker size="large" style={{ width: '100%', background: '#f5f5f5' }}
                                value={filterData?.['fromDate-toDate']}
                                allowClear
                                onChange={(value) => this.handleFilter('fromDate-toDate', value)}
                            />
                        </Col>
                        <Col span={6}>
                            <Button onClick={() => this.search()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.loyalty_usage}
                        excelExport={{ url: Api.loyalty_usage_export, name: "Loyalty Usage History List" }}
                        handleRefetch={fetchData => this.reFetch = fetchData}
                        handleExportExcel={downloadExcel => this.exportExcel = downloadExcel}
                        onDownloadStatusChange={this.onDownloadStatusChange}
                        params={params} />
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(LoyaltyUsageHistory)
