const Theme = {
    colors: {
        primary: '#B6DCFE',
        secondary: '#f5f5f5',
        success: '#28a745',
        card_bg_color: '#f5f5f5',
        input_bg_color: '#f5f5f5',
        default: '#1677ff',
        warning: '#FFCC00'
    }
};

export const Colors = {
    darkGreen: '#006400',
    darkYellow: '#8B8000',
    red: '#FF0000',
    primary: '#c2002f',
    yellow: '#FFFF00',
    gray: '#808080',
    blue: '#0000FF',
    white: '#ffffff',
    black: '#000000',
}


export default Theme;