import { message } from 'antd';
import axios from 'axios';
import { LOC_clear_token, LOC_getAccessToken, LOC_storeToken } from '../core/custom-local-storage';
import { RefreshToken } from '../page/auth/auth-provider';
import Api from './api';
import CustomPath from '../routes/custom-path';
import { MEDIA_TYPE } from './mediaType';
import { HTTP_METHOD } from './httpMethod';

const controllers = new Map();


const axiosInstance = axios.create({
    baseURL: Api.host
});

axiosInstance.interceptors.request.use(
    async config => {
        const token = LOC_getAccessToken()
        config.headers = {
            'Authorization': `Bearer ${token}`,
        }
        const controller = new AbortController();
        config.signal = controller.signal;
        controllers.set(config.url, controller);
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use(
    (response) => {
        controllers.delete(response.config.url);
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            try {
                const responseData = await RefreshToken();
                LOC_storeToken(responseData)
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${responseData.access_token}`;
                return axiosInstance(error.config);
            } catch (refreshError) {
                LOC_clear_token()
                message.error("Session expired! Please login again");
                window.location.href = '/'
            }
        } else if (error.response.status === 500) {
            if (error.response.data && error.response.data.message) {
                message.destroy()
                message.error(error.response.data.message)
            } else {
                message.destroy()
                message.error("Something Wrong!")
            }
        }
        controllers.delete(error.config?.url);
        return Promise.reject(error);
    }
);

export const cancelRequest = (url) => {
    const controller = controllers.get(url);
    if (controller) controller.abort();
};


// export const ApiInstance = async (url, method, mediaType, requestData, specificId, requestParams, customMessage) => {
//     try {
//         let responseData = null
//         let response = null
//         url = specificId ? url + `/${specificId}` : url
//         const header = {
//             headers: {
//                 'Content-Type': mediaType,
//             }
//         }
//         if (method === HTTP_METHOD.GET) {
//             if (requestData) {
//                 response = await axiosInstance.get(url, { params: requestData })
//             } else {
//                 response = await axiosInstance.get(url)
//             }
//             responseData = response.data
//         } else if (method === HTTP_METHOD.POST && mediaType === MEDIA_TYPE.FORM_DATA) {
//             responseData = await axiosInstance.post(url, requestData, header);
//         } else if (method === HTTP_METHOD.POST && mediaType === MEDIA_TYPE.JSON) {
//             responseData = await axiosInstance.post(url, requestData, { params: requestParams });
//         } else if (method === HTTP_METHOD.PUT && mediaType === MEDIA_TYPE.FORM_DATA) {
//             responseData = await axiosInstance.put(url, requestData, header);
//         } else if (method === HTTP_METHOD.PUT && mediaType === MEDIA_TYPE.JSON) {
//             responseData = await axiosInstance.put(url, requestData);
//         } else if (method === HTTP_METHOD.PUT) {
//             responseData = await axiosInstance.put(url, requestData, { params: requestParams })
//         } else if (method === HTTP_METHOD.DELETE) {
//             responseData = await axiosInstance.delete(url);
//         }

//         if (customMessage) {
//             message.destroy()
//             message.success(customMessage)
//         } else {
//             message.destroy()
//             if (method === HTTP_METHOD.POST) {
//                 message.success("Successfully Created")
//             } else if (method === HTTP_METHOD.PUT) {
//                 message.success("Successfully Updated")
//             } else if (method === HTTP_METHOD.DELETE) {
//                 message.success("Successfully Deleted")
//             }
//         }
//         return responseData
//     } catch (error) {

//         throw error;
//     }
// }

export const ApiHandler = async ({ url, method, mediaType, requestData, specificId, requestParams, customMessage, disableShowMessage }) => {

    try {
        let responseData = null
        let response = null
        const finalUrl = specificId ? `${url}/${specificId}` : url
        const header = {
            headers: {
                'Content-Type': mediaType,
            }
        }
        switch (method) {
            case HTTP_METHOD.GET:
                response = await axiosInstance.get(finalUrl, { params: requestParams });
                responseData = response.data;
                break;
            case HTTP_METHOD.POST:
                responseData = await axiosInstance.post(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.PUT:
                responseData = await axiosInstance.put(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.DELETE:
                responseData = await axiosInstance.delete(finalUrl);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }

        message.destroy();

        if (!disableShowMessage) {
            if (customMessage) {
                message.success(customMessage);
            } else {
                switch (method) {
                    case HTTP_METHOD.POST:
                        message.success("Successfully Created");
                        break;
                    case HTTP_METHOD.PUT:
                        message.success("Successfully Updated");
                        break;
                    case HTTP_METHOD.DELETE:
                        message.success("Successfully Deleted");
                        break;
                    default:
                        break;
                }
            }
        }
        return responseData;
    } catch (error) {
        throw error;
    }
}

export const ApiGet = async (url, id, props) => {
    try {
        let compileUrl = url
        if (id) {
            compileUrl = compileUrl + `/${id}`
        }

        const response = await axiosInstance.get(compileUrl);
        return response.data;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
};

export const ApiFilter = async (url, data, props) => {
    try {
        let response;
        if (data) {
            response = await axiosInstance.get(url, { params: data })
        } else {
            response = await axiosInstance.get(url)
        }
        return response.data;

    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiFilterByBody = async (url, params, body, props) => {
    try {
        // const response = await axiosInstance.post(url, body, { headers: { 'Authorization': `Bearer ${props.accessToken}` } });
        const response = await axiosInstance.post(url, body);
        return response.data;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiFilterById = async (url, id, params, props) => {
    try {
        const compileUrl = url + `/${id}`
        if (params) {
            const response = await axiosInstance.get(compileUrl, { params });
            return response.data;
        } else {
            const response = await axiosInstance.get(compileUrl);
            return response.data;
        }

    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiPost = async (url, data, props) => {
    try {
        const response = await axiosInstance.post(url, data);
        message.destroy()
        message.success("Create Successful")
        return response;
    } catch (error) {
        throw error
        // errorHandler(error, props)
        // return error.response.data;
    }
};

export const ApiPostWithParam = async (url, requestParams, data, props) => {
    try {
        const response = await axiosInstance.post(url, data, { params: requestParams });
        message.destroy()
        return response;
    } catch (error) {
        errorHandler(error, props)
        return error.response.data;
    }
}


export const ApiPut = async (url, id, data, props) => {
    try {
        if (id) {
            url = url + `/${id}`
        }
        const response = await axiosInstance.put(url, data);
        message.destroy()
        message.success("Update Successful")
        return response;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
};

export const ApiPutBatch = async (url, data, props) => {
    try {
        const response = await axiosInstance.put(url, data);
        message.destroy()
        message.success("Update Successful")
        return response;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
};

export const ApiDelete = async (url, id, props) => {
    try {
        url = url + `/${id}`
        const response = await axiosInstance.delete(url);
        message.destroy()
        message.success("Delete Successful")
        return response;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiPostWithFormData = async (url, data, props) => {
    try {
        const response = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        message.destroy()
        message.success("Create Successful")
        return response;
    } catch (error) {
        throw error
    }
};

export const ApiPostWithFormDataOrderCreate = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response;
    } catch (error) {
        throw error
    }
};

export const ApiPutWithFormData = async (url, data, props) => {
    try {
        const response = await axiosInstance.put(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        message.destroy()
        message.success("Create Successful")
        return response;
    } catch (error) {
        throw error
    }
};

export const PushNotificatonByTopic = async (id, props) => {
    try {
        const compileUrl = Api.push_notification_topic + `/${id}`
        const response = await axiosInstance.get(compileUrl);
        return response.data;
    } catch (error) {
        throw error
    }
}


const errorHandler = (error, props) => {
    if (error && error.response && error.response.status === 401) {
        props.navigate(CustomPath.login)
    }
}

// export const DownloadOrderFormatExcel = async () => {
//     const url = Api.host + "/" + Api.download_order_sample_excel;
//     let responseData = null
//     try {
//         await axios({
//             method: 'get',
//             url: url,
//             responseType: 'blob',
//         }).then(response => {
//             const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = 'order_excel_template_format.xlsx';
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             responseData = response
//         }).catch(error => {
//             responseData = error
//         });
//     } catch (error) {
//     }
//     return responseData
// }

export const downloadExcel = async (url, params, excelName) => {
    let responseData = null
    let name = excelName ? excelName + ".xlsx" : 'order_excel_template_format.xlsx'
    try {
        await axiosInstance.get(url,
            {
                params: params,
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                responseData = response
            }).catch(error => {
                responseData = error

            });
    } catch (error) {
    }
    return responseData
}

export const uploadAndDownload = async (url, requestData, excelName) => {
    let responseData = null
    let name = excelName ? excelName + ".xlsx" : 'downloaded-excel.xlsx';
    try {
        const header = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob'
        }
        await axiosInstance.post(url, requestData, header).then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
            responseData = response
        }).catch(error => {
            console.log(error)
            responseData = error
        })
    } catch (error) {
        throw error
    }
    return responseData
}

// export const downloadShipperExcel = async (url, params) => {
//     const sourceUrl = Api.host + "/" + url;
//     let responseData = null
//     try {
//         await axios({
//             method: 'get',
//             url: sourceUrl,
//             params: params,
//             responseType: 'blob',
//         }).then(response => {
//             const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = 'Shipper List.xlsx';
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             responseData = response
//         }).catch(error => {
//             responseData = error

//         });
//     } catch (error) {
//     }
//     return responseData
// }

// export const UploadPriceUpdateAndDownload = async (resquestData) => {
//     const url = Api.host + "/" + Api.order_price_bulk_update;
//     let responseData = null
//     try {
//         await axios({
//             method: 'post',
//             url: url,
//             data: resquestData,
//             responseType: 'blob',
//         }).then(response => {
//             const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = 'invalid-tracking-list.xlsx';
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             responseData = response
//         }).catch(error => {
//             responseData = error
//         });
//     } catch (error) {
//     }
//     return responseData
// }

// export const UploadRTSOrderAndDownload = async (resquestData) => {
//     const url = Api.host + "/" + Api.order_rts_excel_upload;
//     let responseData = null
//     try {
//         await axios({
//             method: 'post',
//             url: url,
//             data: resquestData,
//             responseType: 'blob',
//         }).then(response => {
//             const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = 'invalid-rts-tracking-list.xlsx';
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             responseData = response
//         }).catch(error => {
//             responseData = error
//         });
//     } catch (error) {
//     }
//     return responseData
// }

// export const BulkShipperCreateWithExcel = async (resquestData) => {
//     const url = Api.host + "/" + Api.shipper_bulk_create_with_excel;
//     let responseData = null
//     try {
//         await axios({
//             method: 'post',
//             url: url,
//             data: resquestData,
//             responseType: 'blob',
//         }).then(response => {
//             const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = 'invalid-shipper-data.xlsx';
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             responseData = response
//         }).catch(error => {
//             responseData = error
//         });
//     } catch (error) {
//     }
//     return responseData
// }
