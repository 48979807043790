import { EditOutlined, InboxOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Col, DatePicker, Input, Modal, Row, Select, Skeleton, Spin, Table, Tag, Upload, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVButton } from "../../../components/core-component";
import FetchSearchUrl from "../../../components/fetch-searchUrl";
import { TitleLevel3 } from "../../../components/general-component";
import SearchFilter from "../../../components/searchFilter";
import SearchPagination from "../../../components/searchPagination";
import { Constant, MENU_NAME } from "../../../core/constant";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiHandler, downloadExcel, uploadAndDownload } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const { RangePicker } = DatePicker

const { Option } = Select
const { Dragger } = Upload;
class ShipperPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pageSize: Constant.pageSize,
            totalPagination: 0,
            page: 1,
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            updateDivision: {},
            roles: [],
            filterData: { status: 'ACTIVE', approvalStatus: 'APPROVED' },
            isDataFetching: false,
            isUploading: false,
            openUploadExcelModal: false,
            downloading_excel: false,
            fetching_division: false,
            auth_provider: {},
            division_collection: [],
            township_collection: [],
            township_map: {}
        }
        this.inputRef = React.createRef();
        this.updateRef = React.createRef();
    }

    componentDidMount() {
        const { filterData, urlPage } = FetchSearchUrl({ status: 'ACTIVE', approvalStatus: 'APPROVED' })

        let formattedFilterData = { ...filterData };

        if (filterData?.fromDate && filterData?.toDate) {
            formattedFilterData.fromDate = dayjs(filterData?.fromDate)
            formattedFilterData.toDate = dayjs(filterData?.toDate)
        }
        if (filterData['fromDate-toDate']) {
            formattedFilterData['fromDate-toDate'] = [dayjs(filterData?.fromDate), dayjs(filterData?.toDate)];
        }

        if (urlPage) {
            this.setState({
                filterData: formattedFilterData
            }, () => this.fetchData(urlPage))
        }

        this.setState({
            auth_provider: this.props.menuAccessMap['SHIPPER'],
        })
    }

    fetchData = async (pageNumber) => {
        const { pageSize, page, filterData } = this.state

        this.setState({
            isDataFetching: true
        })
        try {
            let params = {
                size: pageSize,
                page: pageNumber ? pageNumber - 1 : page - 1,
                ...filterData,
                'businessName': filterData?.businessName,
                'phone': filterData?.phone,
                'divisionName': filterData?.divisionName?.label || filterData?.divisionName,
                'townshipName': filterData?.townshipName?.label || filterData?.townshipName,
                'fromDate': (filterData['fromDate-toDate'] && filterData.fromDate) && filterData.fromDate.format("YYYY-MM-DD"),
                'toDate': (filterData['fromDate-toDate'] && filterData.toDate) && filterData.toDate.format("YYYY-MM-DD"),
                'fromDate-toDate': filterData['fromDate-toDate'] && `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`,
            }
            const response = await ApiHandler({ url: Api.shipper_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams: params })
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handleFileUpload = async (file) => {
        try {
            this.setState({
                isUploading: true
            })
            let formData = new FormData()
            formData.append('file', file);
            await uploadAndDownload(Api.shipper_bulk_create_with_excel, formData, "invalid-shipper-data")
            message.success("Successfully Uploaded")
            this.fetchData()
        } catch (error) {
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
        this.setState({
            isUploading: false,
            openUploadExcelModal: false
        })
    };
    updateFilterData = (key, value) => {
        let { filterData } = this.state
        const { townshipCollectionMap } = this.props;
        filterData = {
            ...filterData,
            [key]: value
        }

        if (key === 'divisionName') {
            const townshipLists = townshipCollectionMap[value?.value];

            if (townshipLists) {
                this.setState({
                    township_collection: townshipLists
                });
            }
        }
        if (key === 'fromDate-toDate' && value) {
            filterData = { ...filterData, fromDate: value[0], toDate: value[1], 'fromDate-toDate': value }
        } else {
            filterData = { ...filterData, 'fromDate': null, 'toDate': null }
        }
        if (key === 'approvalStatus' && value === 'PENDING') {
            filterData = { ...filterData, status: 'INACTIVE' }
        }
        this.setState({
            filterData: filterData
        })
    }
    handleRoleChange = (value) => {
        this.setState({
            searchRole: value
        })
    }
    search = () => {
        const { filterData } = this.state;

        let formattedFilterData = { ...filterData };
        if (filterData.fromDate) {
            formattedFilterData.fromDate = dayjs(filterData.fromDate).format("YYYY-MM-DD");
        }
        if (filterData.toDate) {
            formattedFilterData.toDate = dayjs(filterData.toDate).format("YYYY-MM-DD");
        }

        if (filterData['fromDate-toDate']) {
            formattedFilterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
        } else {
            if (!filterData.fromDate) {
                formattedFilterData.fromDate = null;
            }
            if (!filterData.toDate) {
                formattedFilterData.toDate = null;
            }
        }
        if (filterData.divisionName) {
            formattedFilterData.divisionName = filterData.divisionName.label || filterData.divisionName;
        }
        if (filterData.townshipName) {
            formattedFilterData.townshipName = filterData.townshipName.label || filterData.townshipName;
        }

        const newUrl = SearchFilter(formattedFilterData);
        window.history.pushState({}, '', newUrl)
        this.fetchData(1)
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber);
    };

    downloadExcel = async () => {
        const { filterData } = this.state
        this.setState({
            downloading_excel: true
        })
        try {
            let params = {
                'businessName': filterData?.businessName,
                'phone': filterData?.phone,
                'approvalStatus': filterData?.approvalStatus,
                'status': filterData?.status,
                'salePersonCode': filterData?.salePersonCode,
                'divisionName': filterData?.divisionName?.label,
                'townshipName': filterData?.townshipName?.label,
                'fromDate': (filterData['fromDate-toDate'] && filterData.fromDate) && filterData.fromDate.format("YYYY-MM-DD"),
                'toDate': (filterData['fromDate-toDate'] && filterData.toDate) && filterData.toDate.format("YYYY-MM-DD"),
            }
            await downloadExcel(Api.shipper_download_execel, params, "Shipper List")
        } catch (error) {
        }
        this.setState({
            downloading_excel: false
        })
    }
    closeUploadModal = () => {
        const { isUploading } = this.state
        if (isUploading) {
            message.error("You can not close at this time.")
        } else {
            this.setState({ openUploadExcelModal: false })
        }
    }
    render() {
        const { data, totalPagination, page, pageSize, isDataFetching, openUploadExcelModal, isUploading, downloading_excel, township_collection, fetching_division, filterData } = this.state
        const currentSearchUrl = window.location.search;

        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };

        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Business Name',
                key: 'businessName',
                render: (shipper) => (
                    <>
                        {
                            shipper.business ?
                                <span>{shipper.business.businessName}</span>
                                :
                                <></>

                        }
                    </>

                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Division & Township',
                key: 'township',
                render: (shipper) => (
                    <>
                        {
                            shipper.township && shipper.township.division ?
                                <span>{shipper.township.division.name} /{shipper.township.name}</span>
                                :
                                <></>

                        }
                    </>

                ),
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (shipper) => (
                    <div>
                        <Link to={`${CustomPath.shipper_update}/${shipper.id}`} state={{ route: `${CustomPath.shipper}${currentSearchUrl}` }}><Button size={'middle'} type="primary" shape="circle" style={{ marginBottom: 5 }}><EditOutlined /></Button></Link><br />
                        {/* <Button size={'middle'} type="primary" shape="circle" onClick={() => this.deleteShipper(shipper.id)}><DeleteOutlined /></Button> */}
                    </div>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title={"Upload Shipper Excel file"}
                    centered
                    open={openUploadExcelModal}
                    footer={null}
                    onCancel={() => this.closeUploadModal()}
                    className='custom-modal'
                >
                    <Dragger
                        disabled={isUploading}
                        {...draggerProps}
                        accept={[".xlsx", ".xls"]}
                        maxCount={1}
                        style={{ marginTop: 15 }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">
                            {
                                isUploading ?
                                    <div>
                                        <Spin /> <span> Uploading...</span>
                                    </div>
                                    :
                                    <span>Upload Excel File Only</span>
                            }

                        </p>
                    </Dragger>
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={4} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Shipper"} />
                    </Col>
                    <Col span={20} style={{ textAlign: 'right' }}>
                        {
                            this.props.roleType && (this.props.roleType === 'ADMIN' || this.props.roleType === 'BUSINESS_ADMIN' || this.props.roleType === 'SM_TEAM_LEADER' || this.props.roleType === 'SM_DATA_ANALYSIS') ?
                                <Button type="primary" menuName={MENU_NAME.SHIPPER} onClick={() => this.downloadExcel()} style={{ backgroundColor: 'green', marginRight: 10 }} loading={downloading_excel}>Export Excel</Button>
                                // <NJVButton type="primary" menuName={MENU_NAME.SHIPPER} onClick={() => this.downloadExcel()} style={{ backgroundColor: 'green', marginRight: 10 }} loading={downloading_excel} text='Export Excel' />
                                :
                                <></>
                        }
                        <NJVButton type="primary" style={{ marginRight: 10 }} onClick={() => this.setState({ openUploadExcelModal: true })} menuName={MENU_NAME.SHIPPER} text="Bulk Shipper Create" />
                        <NJVButton type="primary" menuName={MENU_NAME.SHIPPER} onClick={() => this.props.navigate(CustomPath.shipper_create)} text="Create" icon={<PlusCircleFilled />} />
                    </Col >
                </Row >
                <Row gutter={[24, 24]} style={{ marginBottom: 30 }}>
                    <Col span={6}>
                        <Input
                            value={filterData.businessName}
                            onChange={(event) => this.updateFilterData('businessName', event.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by business name"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            value={filterData?.phone}
                            onChange={(event) => this.updateFilterData('phone', event.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by phone"
                        />
                    </Col>
                    <Col span={6}>
                        <RangePicker size="large" style={{ width: '100%', background: '#f5f5f5' }}
                            value={filterData['fromDate-toDate']}
                            allowClear
                            onChange={(value) => this.updateFilterData('fromDate-toDate', value)} />
                    </Col>
                    <Col span={6}>
                        <Select
                            value={filterData?.divisionName}
                            className="custom-selector-gray"
                            onChange={(_, obj) => this.updateFilterData('divisionName', obj)}
                            size="large"
                            allowClear={true}
                            style={{ width: '100%' }}
                            placeholder="Select division"
                            options={this.props?.divisionCollection?.map(division => {
                                return {
                                    value: division.id,
                                    label: division.name,
                                    type: 'division'
                                }
                            })}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            value={filterData?.townshipName}
                            className="custom-selector-gray"
                            onChange={(_, obj) => this.updateFilterData('townshipName', obj)}
                            size="large"
                            allowClear={true}
                            showSearch
                            optionFilterProp="label"
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            style={{ width: '100%' }}
                            placeholder="Select township"
                            loading={fetching_division}
                            options={township_collection?.map(township => {
                                return {
                                    value: township.id,
                                    label: township.name,
                                    type: 'township'
                                }
                            })}
                        />
                    </Col>
                    <Col span={6}>
                        <Select
                            className="custom-selector-gray"
                            onChange={(value) => this.updateFilterData('approvalStatus', value)}
                            size="large"
                            allowClear={true}
                            value={filterData?.approvalStatus}
                            style={{ width: '100%' }}
                            placeholder="Select user status"
                        >
                            <Option value="APPROVED">Approved</Option>
                            <Option value="PENDING">Pending</Option>
                            <Option value="REJECT">Reject</Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        <Select
                            className="custom-selector-gray"
                            onChange={(value) => this.updateFilterData('status', value)}
                            size="large"
                            allowClear={true}
                            style={{ width: '100%' }}
                            value={filterData?.status}
                            placeholder="Select user status"
                        >
                            <Option value="ACTIVE">Active</Option>
                            <Option value="INACTIVE">Inactive</Option>
                        </Select>
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={(event) => this.updateFilterData('salePersonCode', event.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by sale person code"
                        />
                    </Col>
                    <Col span={6}>
                        <Button size="large" shape="square" onClick={() => this.search()} type="primary" style={{ width: '100%' }}>Search</Button>
                    </Col>
                </Row>
                {
                    isDataFetching ?
                        <Skeleton active />
                        :
                        <Table
                            style={{ borderRadius: 10 }}
                            pagination={{
                                position: 'top' | 'bottom',
                                total: totalPagination,
                                current: page,
                                onChange: this.handlePaginationChange,
                                defaultPageSize: pageSize,
                                showSizeChanger: false
                            }}
                            columns={columns}
                            dataSource={data}

                            rowClassName={getRowClassName} />
                }
            </>
        );
    }
}

export default compose(withRouter)(ShipperPage)