import { compose } from "@reduxjs/toolkit";
import { Button, Card, Col, Input, List, Modal, Row, Segmented, Select, Skeleton, Tag, message } from "antd";
import React from "react";
import { TitleLevel3 } from "../../components/general-component";
import { Constant } from "../../core/constant";
import '../../css/push-notification.css';
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import Theme from "../../components/theme";
import { SendOutlined } from '@ant-design/icons';

class PushNotification extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            data: [],
            pushId: '',
            messageTitle: '',
            messageContent: '',
            page: 1,
            pageSize: Constant.pageSize,
            openConfirmationModal: false,
            initialState: true,
            isSending: false,
            searchChannel: '',
            searchTitle: '',
            searchUserType: '',
            isDataFetching: false
        }
    }
    componentDidMount() {
        this.fetchData()
        this.fetchUserTypes()
    }
    fetchUserTypes = async () => {
        try {
            const requestParams = {
                enumType: 'USER_TYPE'
            }
            const response = await ApiHandler({ url: Api.enum_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                let userTemp = []
                userTemp.push({
                    value: 'ALL',
                    label: 'ALL',
                });
                response.map((value) => {
                    userTemp.push(value);
                })
                this.setState({
                    userTypes: userTemp
                });
            }
        } catch (error) {

        }
    }


    fetchData = async (pageNumber) => {
        const { page, filterData, pageSize } = this.state
        this.setState({
            isDataFetching: true
        })
        const requestParams = {
            ...filterData,
            userType: filterData?.userType ? filterData?.userType === 'ALL' ? null : filterData.userType : null,
            size: pageSize,
            page: pageNumber ? pageNumber - 1 : page - 1
        }
        await ApiHandler({ url: Api.push_notification_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                });
            }).catch(error => {

            }).finally(() => {
                this.setState({
                    isDataFetching: false,
                })
            })
    }

    pushNotification = async () => {
        const { pushId } = this.state
        this.setState({ isSending: true })
        try {
            const response = await ApiHandler({ url: Api.push_notification_topic, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, specificId: pushId, disableShowMessage: true })
            if (response) {
                this.handleModal('', false)
                // message.success(response)
                this.setState({
                    initialState: true,
                    isSending: false,
                    pushId: '',
                    messageTitle: '',
                    messageContent: ''
                })
            }
        } catch (error) {
            this.setState({
                initialState: true,
                isSending: false,
                pushId: '',
                messageTitle: '',
                messageContent: ''
            })
        }


    }

    handleModal = (id, value) => {
        const { data } = this.state
        let title = ''
        let content = ''
        if (id !== '' && id !== undefined)
            data.map((noti) => {
                if (noti.id === id) {
                    title = noti.title
                    content = noti.content
                }
            })
        this.setState({
            openConfirmationModal: value,
            pushId: value ? id : '',
            messageTitle: title,
            messageContent: content

        })
    }

    handleViewSegmented = () => {
        this.setState({
            initialState: !this.state.initialState
        })
    }

    onChangeFilter = (key, value) => {
        this.setState(prev => ({
            filterData: {
                ...prev.filterData,
                [key]: value
            }
        }))
    }

    render() {
        const { data, openConfirmationModal, messageTitle, messageContent, initialState, isSending, userTypes, isDataFetching } = this.state
        return (
            <>
                <Modal
                    title="Please confirm your notification content"
                    open={openConfirmationModal}
                    onOk={() => this.deleteRoleConfirm()}
                    onCancel={() => this.handleModal('', false)}
                    okText="Confirm"
                    cancelText="Cancel"
                    footer={null}
                >
                    <>
                        <Segmented
                            size="large"
                            block
                            onChange={this.handleViewSegmented}
                            options={['Initial State', 'Expanded View']} />
                        <br />
                        <div className="mobile-container">
                            <div className="mobile-status-bar" />
                            <div className="noti-container">
                                <div style={{
                                    fontWeight: '500',
                                    fontSize: 14
                                }}>
                                    {messageTitle}
                                </div>
                                <div className={`${initialState ? 'content-style-initial' : 'content-style-expand'} `}>
                                    {messageContent}
                                </div>
                            </div>


                        </div>
                        <div style={{ textAlign: 'center', marginTop: 15 }}>
                            {
                                isSending ?
                                    <Button
                                        size="large"
                                        loading={isSending}
                                        type="primary">
                                        Sending
                                    </Button>
                                    :
                                    <Button
                                        onClick={() => this.pushNotification()}
                                        size="large"
                                        type="primary">
                                        Confirm
                                    </Button>
                            }

                        </div>
                    </>
                </Modal>

                <Row gutter={[16, 24]} >
                    <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Send Notification"} />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={(e) => this.onChangeFilter('channel', e.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by Channel"
                            name="channel"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={(e) => this.onChangeFilter('title', e.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by Title"
                            name="title"
                        />
                    </Col>
                    <Col span={6} >
                        <Select
                            size="large"
                            className="custom-selector-gray"
                            defaultValue="ALL"
                            onChange={(value) => this.onChangeFilter('userType', value)}
                            style={{
                                width: '100%'
                            }}
                            options={userTypes}
                        />
                    </Col>
                    <Col span={6} className="search">
                        <Button onClick={() => this.fetchData()} type="primary" style={{ borderRadius: 10, height: 40, width: '100%' }}>Search</Button>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <List
                                    pagination={{
                                        position: 'bottom',
                                        align: 'end',
                                    }}
                                    dataSource={data}
                                    renderItem={(item, index) => (
                                        <Row>
                                            <Col span={24} style={{ marginBottom: 20 }}>
                                                {/* className="push-notification" */}
                                                <Card title={item.title} extra={<Button type="text" icon={<SendOutlined />} style={{ fontWeight: '600', color: Theme.colors.default }} onClick={() => this.handleModal(item.id, true)}>Send Notification</Button>}>
                                                    <div className="title-label">Content</div>
                                                    <div className="label">{item.content}</div>
                                                    {
                                                        item.pushToSpecificUser ?
                                                            <div className="title-label">Receipent  <Tag color="#108ee9">Specific User</Tag></div> :
                                                            <div className="title-label">Receipent  <Tag color="#108ee9">{item.userType}</Tag></div>
                                                    }



                                                </Card>

                                            </Col>
                                        </Row>
                                    )} />
                        }
                    </Col>
                </Row>

            </>

        )
    }
}

export default compose(withRouter)(PushNotification)