import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Form, Image, Input, message, Modal, Radio, Row, Select, Skeleton, Upload } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import { checkValidFileSize } from "../../../components/validator";
import { MEDIA_TYPE } from "../../../network/mediaType";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const { TextArea } = Input
class StoreUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            previewImage: null,
            previewTitle: null,
            currentDate: '',
            logoFileList: [],
            isLoading: false,
            previewOpen: false,
        };
        this.formRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isLoading: true
        })
        await ApiHandler({ url: Api.store_get_by_id, method: HTTP_METHOD.GET, specificId: this.props.params.id })
            .then((response) => {
                this.setState({ data: response }, () => {
                    this.formRef?.current?.setFieldsValue({ ...response })
                })
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };

    updateStoreForm = async (values) => {
        const { data } = this.state

        this.setState({
            isUpdating: true
        })
        let formData = new FormData()
        formData.append('storeName', values.storeName);
        formData.append('ninjaStoreType', values.ninjaStoreType);
        formData.append('description', values.description ? values.description : '');
        formData.append('statusFlag', values.statusFlag);

        if (values?.storeLogo?.file) {
            formData.append('storeLogo', values.storeLogo.file)
        } else {
            formData.append('storeLogo', this.state.data.logo)
        }
        await ApiHandler({ url: Api.store_update, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, specificId: data.id })
            .then(() => { this.props.navigate(CustomPath.store) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isUpdating: false
                })
            })
    }

    handleChange = (type, uploadObject) => {

        if (checkValidFileSize(uploadObject.file)) {
            if (type === 'logo') {
                this.setState({
                    logoFileList: uploadObject.fileList
                })
            }
        }
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    onFinishFailed = () => {
        message.destroy()
        message.error('Please fill required fields');
    }

    render() {
        const { navigate } = this.props
        const { isUpdating, logoFileList, previewOpen, previewImage, previewTitle, data, isLoading } = this.state

        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.store}>Store</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />

                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Store Update Form"}
                    extra={<Button type="primary" onClick={() => navigate(CustomPath.store)}>Back</Button>}
                >
                    {
                        isLoading ? <Skeleton /> :
                            <Form
                                ref={this.formRef}
                                layout="vertical"
                                onFinish={this.updateStoreForm}
                                onFinishFailed={this.onFinishFailed}
                            >
                                <Row gutter={[24, 16]}>
                                    <Col span={24}>
                                        {
                                            data?.logo &&
                                            <Col span={24}>
                                                <Form.Item name="logo">
                                                    <Image width={120} height={120} style={{ border: '1px dashed gray', borderRadius: '50%' }} src={data?.logo} />
                                                </Form.Item>
                                            </Col>
                                        }
                                        {/* {
                                            data?.logo ?
                                                <Col span={4}>
                                                    <Form.Item name="logo">
                                                        <Image width={120} height={120} style={{ border: '1px dashed gray', borderRadius: '50%' }} src={data?.logo} />
                                                    </Form.Item>
                                                    <Button onClick={() => this.setState(prevState => ({
                                                        data: {
                                                            ...prevState.data,
                                                            logo: null
                                                        }
                                                    }))}>Remove</Button>
                                                </Col>
                                                : <Col span={12}>
                                                    <Form.Item name="logo" label={"Upload Logo"}>
                                                        <Upload
                                                            accept={["image/png", "image/jpg", "image/jpeg"]}
                                                            listType="picture-card"
                                                            fileList={logoFileList}
                                                            multiple={false}
                                                            maxCount={1}
                                                            action={null}
                                                            onPreview={this.handlePreview}
                                                            onChange={(obj) => this.handleChange('logo', obj)}
                                                            beforeUpload={() => false}
                                                        >
                                                            {logoFileList.length >= 1 ? null :
                                                                <div>
                                                                    <PlusOutlined />
                                                                    <div
                                                                        style={{
                                                                            marginTop: 8,
                                                                        }}
                                                                    >
                                                                        Upload
                                                                    </div>
                                                                </div>}
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                        } */}
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="specialCode" label={"Special Code"}>
                                            <Input disabled />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="storeName" label={"Store Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter store name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="ninjaStoreType" label={"Store Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Select
                                                size="large"
                                                style={{
                                                    width: '100%',
                                                }}
                                                placeholder="Select store type"
                                                options={[
                                                    {
                                                        label: 'Restaurant',
                                                        value: 'RESTAURANT',
                                                    },
                                                ]}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="description" label={"Description"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <TextArea
                                                style={{ height: 60 }} placeholder={"Enter description"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            name="statusFlag">
                                            <Radio.Group>
                                                <Radio value={'ACTIVE'}>Active</Radio>
                                                <Radio value={'INACTIVE'}>Inactive</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" loading={isUpdating}>Update</Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                    }
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(StoreUpdate)