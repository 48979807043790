import { Skeleton, Table } from 'antd';
import React from 'react';
import { Constant } from '../core/constant';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import { ApiHandler, downloadExcel } from '../network/network-manager';
import FetchSearchUrl from './fetch-searchUrl';
import SearchPagination from './searchPagination';

export default class NJVTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            page: 1,
            pageSize: Constant.pageSize,
            totalPagination: 0,
            isDataFetching: false
        }
    }

    componentDidMount() {
        const { urlPage, params } = FetchSearchUrl({ ...this.props.defaultFilter })

        if (urlPage) {
            this.setState({
                params
            }, () => this.fetchData(urlPage))
        }

        this?.props?.handleRefetch?.(this.fetchData)
        this?.props?.handleExportExcel?.(this.downloadExcel)
    }

    fetchData = async (pageNumber) => {
        const { page, pageSize } = this.state

        const requestParams = {
            page: pageNumber ? pageNumber - 1 : page - 1,
            size: pageSize,
            ...this.state.params,
            ...this.props.params
        }

        this.setState({
            isDataFetching: true
        })
        await ApiHandler({ url: this.props.apiUrl, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            .then(response => {
                this.setState({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page
                })
            }).catch((error) => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    downloadExcel = async () => {
        let { page, pageSize } = this.state
        if (this.props.onDownloadStatusChange) {
            this.props.onDownloadStatusChange(true);
        }
        let params = {
            page: page - 1,
            size: pageSize,
            ...this.props.params
        }
        await downloadExcel(this.props.excelExport.url, params, this.props.excelExport.name)
            .then(() => { })
            .catch(() => {
            }).finally(() => {
                if (this.props.onDownloadStatusChange) {
                    this.props.onDownloadStatusChange(false);
                }
            })
    }

    handlePaginationChange = (pageNumber) => {
        const newUrl = SearchPagination(pageNumber)
        window.history.pushState({}, '', newUrl)
        this.fetchData(pageNumber)
    };

    render() {
        const { data, page, pageSize, totalPagination, isDataFetching } = this.state;

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            ...this.props.columns
        ]

        const getRowClassName = (_, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };

        return (
            <>
                {
                    isDataFetching ?
                        <Skeleton active />
                        :
                        <Table
                            rowKey="id"
                            pagination={{
                                position: 'top' | 'bottom',
                                total: totalPagination,
                                current: page,
                                onChange: this.handlePaginationChange,
                                defaultPageSize: pageSize,
                                showSizeChanger: false
                            }}
                            columns={columns}
                            dataSource={data}
                            rowClassName={getRowClassName}
                        />
                }
            </>

        )
    }
}