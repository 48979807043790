import { Button, Col, Form, Input, Modal, Row, Select, Tag } from 'antd'
import { EditOutlined } from "@ant-design/icons";
import React, { Component } from 'react'
import { TitleLevel3 } from '../../../components/general-component'
import NJVTable from '../../../components/njv-table'
import Api from '../../../network/api'
import { Link } from 'react-router-dom'
import CustomPath from '../../../routes/custom-path'
import { NJVAddButton, NJVInput, NJVSelect } from '../../../components/core-component';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../../network/with-router';
import Theme from '../../../components/theme';

class StorePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            storeLoginModal: false,
            isLoggingIn: false,
        }
    }

    handleFilterData = (key, value) => {
        this.setState(prevState => ({
            filterData: {
                ...prevState.filterData,
                [key]: value
            }
        }));

        if (key === 'type') {
            this.setState(prevState => ({
                filterData: {
                    ...prevState.filterData,
                    type: value?.value
                }
            }));
        }
    }
    render() {
        const { storeLoginModal, isLoggingIn, filterData } = this.state;

        const columns = [
            {
                title: 'Store Name',
                key: 'storeName',
                dataIndex: 'storeName'
            },
            {
                title: 'Special Code',
                key: 'specialCode',
                dataIndex: 'specialCode'
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { statusFlag }) => (
                    <>
                        {
                            (
                                statusFlag === "ACTIVE" ? <Tag color="#87d068">{statusFlag}</Tag> : <Tag color="#cd201f">{statusFlag}</Tag>
                            )
                        }
                    </>
                ),

            }, {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (store) => (
                    <Link to={`${CustomPath.store_update}/${store.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                ),
            },
        ]

        return (
            <>
                <Modal
                    open={storeLoginModal}
                    onCancel={() => this.setState({ storeLoginModal: false })}
                    title="Login"
                    footer={null}
                >
                    <Form onFinish={this.handleStoreLogin}>
                        <Form.Item label="Phone Number" name="username" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVInput bgcolor={Theme.colors.secondary} placeholder="Phone Number" />
                        </Form.Item>
                        <Form.Item label="Password" name="password" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVInput bgcolor={Theme.colors.secondary} ispasswordinput placeholder="Password" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isLoggingIn}>Login</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Store"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.store_create}><NJVAddButton /></Link>
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={(e) => this.handleFilterData('name', e.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by name"
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            onChange={(e) => this.handleFilterData('specialCode', e.target.value)}
                            style={{ background: '#f1f1f1', height: 40, marginRight: 8 }}
                            placeholder="Search by special code"
                        />
                    </Col>
                    <Col span={6}>
                        <NJVSelect
                            size="large"
                            bgcolor={Theme.colors.secondary}
                            style={{
                                width: '100%'
                            }}
                            allowClear
                            onChange={(_, object) => this.handleFilterData('type', object)}
                            placeholder="Select store type"
                            options={[
                                {
                                    label: 'Restaurant',
                                    value: 'RESTAURANT',
                                },
                            ]}
                        />
                    </Col>
                    <Col span={6}>
                        <Button onClick={() => this.reFetch()} type="primary" style={{ height: 40, width: '100%' }}>Search</Button>
                    </Col>
                    <Col span={24}>
                        <NJVTable
                            columns={columns}
                            apiUrl={Api.store_filter}
                            params={filterData}
                            handleRefetch={fetchData => this.reFetch = fetchData}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(StorePage)