import { Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearCookie } from "../../redux/cookie-slice";
import CustomPath from "../../routes/custom-path";
import { removeMenu } from "../../redux/menu-slice";
import { clearProfile } from "../../redux/profile-slice";

function Logout() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(clearCookie())
        dispatch(removeMenu())
        navigate(CustomPath.app)
        dispatch(clearProfile());
    }, [dispatch, navigate])

    return (
        <>
            <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Spin size='large' />
            </div>
        </>
    )

}

export default Logout