
const Api = {
    host: process.env.REACT_APP_SERVER_URL,
    login: 'api/auth/login',
    authenticate_with_otp: 'api/auth/authorized-with-otp',
    refresh_token: '/api/auth/refresh_token',
    base_user_summary: '/dashboard/admin',
    base_user_firebase_token: 'user/firebase_token_update',

    enum_filter: 'enum/filter',

    core_config_filter: 'core_config/filter',
    core_config: 'core_config',

    download_order_sample_excel: 'order/download_order_sample_excel',

    user: 'user',
    user_filter: 'user/filter',

    admin: 'admin',
    admin_filter: 'admin/filter',

    driver: 'driver',
    driver_filter: 'driver/filter',
    driver_location_logs: 'driver/fetch-location-logs',
    driver_location_log: 'driver/fetch-location-log',
    driver_activity: 'driver/logs',
    driver_inventory_stock_update: 'driver/inventory/stock-update',
    driver_inventory_get_current_pickup_count: 'driver/inventory/get-pickup-count',
    driver_inventory_awb_record: 'driver/inventory/awb-record',
    driver_inventory_record_export: 'driver/inventory/export',

    shipper: 'shipper',
    shipper_filter: 'shipper/filter',
    shipper_approval_filter: 'shipper/approval_filter',
    shipper_approve: 'shipper/approveShipper',
    shipper_pending_count: 'shipper/pending_count',
    shipper_count: 'shipper/count-summary',
    shipper_download_execel: 'shipper/export',
    shipper_address: 'user_address/get_default_address',
    shipper_bulk_create_with_excel: 'shipper/bulk-order-create-with-excel',
    shipper_owed: 'shipper/owed-shipper/filter',
    shipper_owed_excel: 'shipper/owed-shipper',
    shipper_owed_approve: 'shipper/settlement/approve',
    shipper_owed_reject: 'shipper/settlement/reject',
    shipper_notification_channel_subscribers: 'shipper/notification/subscribe-shippers',
    shipper_notification_channel_unsubscribers: 'shipper/notification/unsubscribe-shippers',

    loyalty_shop: 'api/v1/loyalty-shop',
    loyalty_shop_filter: 'api/v1/loyalty-shop/filter',
    shipper_loyalty: 'api/v1/loyalty/filter',
    loyalty_usage: 'api/v1/loyalty/usage-history',
    loyalty_usage_export: 'api/v1/loyalty/export-history',
    loyalty_sync: 'api/v1/loyalty/multiple-user-update-loyalty-point',

    banner: 'api/v1/banner',
    banner_list: 'api/v1/banner/list',
    banner_item: 'api/v1/banner/banner-item',
    banner_items: 'api/v1/banner/banner-items',

    confirm_rom_order: 'order/confirm-return-order',
    create_return_order: 'order/register-return-order',

    partnership_admin: 'partnership',
    partnership_store: 'partnership/store',
    partnership_store_filter: 'partnership/store/filter',
    partnership_store_update_pickup_drop_off: 'partnership/store/update-pickup-drop-off',

    user_group: 'user-group',
    user_group_filter: 'user-group/filter',
    no_group_user: 'user-group/non-grouping-users',
    add_user_to_group: 'user-group/register-into-group',
    remove_user_from_group: 'user-group/remove-user-from-group',
    get_group_member: 'user-group/get_group_member',
    user_group_memeber_filter: 'user-group/users/filter',

    pricing_group: 'pricing-group',
    pricing_group_filter: 'pricing-group/filter',

    division_get_all: 'division/getAll',
    division_get_enable_bus_gate_service: 'division/get_enable_bus_gate_service',

    busgate_filter: 'bus-gate/filter',
    bus_gate: 'bus-gate',
    bus_gate_by_receiver_and_sender: 'bus-gate/findByAvailableTownship',
    check_bus_gate_service_available: 'bus-gate/checkBusGateByTownship',

    role: 'role',
    role_authority: 'role/authority',
    role_filter: 'role/filter',
    role_type: 'role/role_type',
    user_type: 'role/user_type',
    role_filter_user_type: 'role/filter_by_usertype',

    division_filter: 'division/filter',
    division: 'division',
    division_batch_update_availability: 'division/batch_update_availability',
    division_batch_update_home_delivery: 'division/batch_update_home_delivery',
    division_filter_active: 'division/enable',

    township_filter: 'township/filter',
    township: 'township',
    township_batch_update_availability: 'township/batch_update_availability',
    township_batch_update_home_delivery: 'township/batch_update_home_delivery',
    township_batch_update_bus_gate_service: 'township/batch_update_bus_gate_service',
    township_filter_active: 'township/enable',

    user_address_filter: 'user_address/filter',
    user_address: 'user_address',

    coverage: 'coverage',
    coverage_type: 'coverage/coverage_type',
    coverage_filter: 'coverage/filter',

    product_category: 'api/product_category',
    product_category_filter: 'api/product_category/filter',

    business_profile: 'business_profile',
    business_profile_filter: 'business_profile/filter',

    notification_channel: 'notification_channel',
    notification_channel_filter: 'notification_channel/filter',
    notification_channel_add_subscriber: 'notification_channel/add-shippers-to-channel',
    notification_channel_remove_subscriber: 'notification_channel/remove-shippers-from-channel',


    notification_history: 'notification_history/filter',



    push_notification_topic: 'notification/topic',
    push_notification_filter: 'notification_channel/active_filter',

    delivery_charges_type: 'order/delivery_charges_status',

    order: 'order',
    order_create: 'order/create_order',
    order_filter: 'order/filter',
    order_check_same_day: 'order/check_same_day_available',
    order_download_execel: 'order/export',
    order_price_bulk_update: 'order/update/excel-upload',
    order_issue: 'order/issues',
    order_issue_update_to_complete: 'order/issue/complete',
    upload_order_excel: 'order/admin/convert-excel-to-order',
    order_get_events: 'order/events-by-order-id',

    order_rts: 'order/return_to_sender/filter',
    order_rts_excel_upload: 'order/return_to_sender/register-with-excel',
    order_rts_single: 'order/return_to_sender/register-single-rts-order',

    order_partnership_stores: 'order/partnership-stores',

    pickup_history: 'pickup/history/filter',

    nearby: 'nearby',
    nearby_filter: 'nearby/filter',
    nearby_type: 'nearby/nearby_type',

    zone: 'zone',
    zone_filter: 'zone/filter',
    zone_price_filter: 'zone/zonePrice/filter',
    zone_price_create: 'zone/zonePrice',
    get_price: 'zone/get_price_script',
    zone_get_unassign_township: 'zone/withoutZoneTownship',
    zone_get_unassign_township_except: 'zone/withoutZoneTownshipWith',
    get_townships_with_zone_id_and_pricing_group_id: 'township-price/zone-id-and-pricing-group-id',
    update_township_price: 'township-price',


    faq: 'faq',
    faq_filter: 'faq/filter',
    faq_type: 'faq/faq_type',
    faq_update_sorting: 'faq/update-sorting',

    redis_cache: 'redis',
    redis_cache_remove_by_key: 'redis/remove-by-key',
    redis_cache_filter: 'redis/all',
    redis_cache_login_failure_rate_logs: 'redis/login-failure-rate-logs',

    sos_filter: 'sos/filter',
    sos_count: 'sos/pending-count',
    sos: 'sos',

    upload_announcement_excel: 'shipper/owed-shipper',

    logs: 'public/logs',
    shipper_activity: 'redis/shipper-activity',
    admin_activity_log: 'admin-activity-log/filter',
    announcement: '/cache/owed-shipper-log',

    webhook_update_order_status: 'webhook/v2/event',

    insurance_filter: 'api/v1/parcel-insurance/filter',
    download_insurance_excel: 'api/v1/parcel-insurance/export-excel',

    ticket_repair_filter: 'api/v1/ticket-repair/filter',
    ticket_repair_receive: 'api/v1/ticket-repair/receive-by-it',
    ticket_repair_deliver_to_shop: 'api/v1/ticket-repair/deliver-to-shop',
    ticket_repair_returned_to_it: 'api/v1/ticket-repair/returned-to-it',
    ticket_repair_complete: 'api/v1/ticket-repair/completed',

    tickets: 'api/v1/tickets',

    department: 'api/v1/department',
    department_filter: 'api/v1/department/filter',

    team: 'api/v1/team',
    team_filter: 'api/v1/team/findAllByDepartment',

    employee: 'api/v1/employee',
    employee_filter: 'api/v1/employee/filter',

    feedback: 'api/v1/feedbacks',

    org_chart: 'api/v1/orgChart',
    org_chart_process_stage: 'api/v1/orgChart/org-process-stage',
    org_chart_participant: 'api/v1/orgChart/org-participant',
    org_chart_participant_unassigned_participants: 'api/v1/orgChart/org-participant/get-unassigned-participants',

    customer: 'api/v1/customer',
    customer_filter: 'api/v1/customer/filter',

    employee_complaints: 'api/v1/employee-complaints',

    wallet_transactions: 'api/v1/wallet/admin/transaction/filter',
    wallets: 'api/v1/wallet/admin/filter',
    deposit: '/api/v1/wallet/admin/deposit',

    check_passcode: 'api/v1/wallet/employee/check-passcode',
    setup_passcode: 'api/v1/wallet/employee/setup-wallet-passcode',
    change_passcode: 'api/v1/wallet/employee/change-wallet-passcode',
    validate_passcode: 'api/v1/wallet/admin/validate-passcode',

    store_filter: 'api/v1/ninja-store/filter',
    store_create: 'api/v1/ninja-store/register',
    store_update: 'api/v1/ninja-store/modify',
    store_get_by_id: 'api/v1/ninja-store/info',
    store_login: 'api/auth/login'
};

export default Api;
