import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Button, Card, Col, Divider, Image, Modal, Row, Skeleton, Steps, Tag, message } from "antd";
import dayjs from 'dayjs';
import React from 'react';
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { NJVSpacer } from "../../components/core-component";
import { SvgCheck, SvgOrderIncomplete, SvgOrderOutlined } from "../../components/custom-svg";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import AirWayBillSlip from "../awb/awb-slip";
import { ParcelEventSteps } from "./parcel-event-steps";
const containerW = '3in'
const containerH = '4in'

const PENDING_PICK_UP = 'PENDING_PICK_UP'
const ARRIVED_AT_SORTING_HUB = 'ARRIVED_AT_SORTING_HUB'
const EN_ROUTE_TO_SORTING_HUB = 'EN_ROUTE_TO_SORTING_HUB'
const ON_VEHICLE_FOR_DELIVERY = 'ON_VEHICLE_FOR_DELIVERY'
const COMPLETED = 'COMPLETED'
const PENDING_RESCHEDULE = 'PENDING_RESCHEDULE'
const RETURNED_TO_SENDER = 'RETURNED_TO_SENDER'
const CANCELLED = 'CANCELLED'

const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)
}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)
}

const StepTitileStyle = ({ title, date, event }) => {
    return (
        <>
            {
                date ?
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                            <span>{title}</span>
                            <div><Tag>{date}</Tag></div>
                        </div>

                        {
                            event.deliveryException ?
                                <>
                                    {
                                        event.deliveryException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.deliveryException.failure_reason}</div>
                                            :
                                            <></>
                                    }


                                    {
                                        event.deliveryException.proof && event.deliveryException.proof.image_uris ?
                                            <div style={{ display: 'flex' }}>
                                                {
                                                    event.deliveryException.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                        {
                            event.deliveryInformation ?
                                <>
                                    {
                                        event.deliveryInformation.proof && event.deliveryInformation.proof.image_uris ?
                                            <div style={{ display: 'flex' }}>
                                                {
                                                    event.deliveryInformation.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                                :
                                <></>
                        }
                        {
                            event.pickupException ?
                                <>
                                    {
                                        event.pickupException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.pickupException.failure_reason}</div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                        {
                            event.pickedUpInformation ?
                                <>
                                    {
                                        event.pickedUpInformation.proof && event.pickedUpInformation.proof.image_uris ?
                                            <div style={{ display: 'flex' }}>
                                                {
                                                    event.pickedUpInformation.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <></>
                                    }

                                </>
                                :
                                <></>
                        }

                    </div >
                    :
                    <>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><span>{title}</span></div>
                    </>
            }
        </>

    )
}


class OrderDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            order_object: props.location.state?.data || {},
            data: {},
            isDataFetching: false,
            viewPhoto: false,
            showOrderCancelConfirmModal: false,
            orderSteps: [],
            currentStep: 0,
            pending_reschedule_comment: null
        }
    }

    componentDidMount() {
        this.setState({
            isDataFetching: true
        })
        this.fetchData()
        this.fetchOrderEvents()
    }

    fetchData = async () => {
        await ApiHandler({ url: Api.order, method: HTTP_METHOD.GET, specificId: this.props.params.id })
            .then(response => {
                let data = response
                let fromAddress = data.fromAddress
                let toAddress = data.toAddress
                if (data?.fromTownship) {
                    fromAddress = fromAddress + "," + data.fromTownship.name
                    if (data.fromTownship?.division) {
                        fromAddress = fromAddress + "," + data.fromTownship.division.name
                    }
                }
                if (data?.toTownship) {
                    toAddress = toAddress + "," + data.toTownship.name
                    if (data.toTownship?.division) {
                        toAddress = toAddress + "," + data.toTownship.division.name
                    }
                }
                data = { ...data, senderAddress: fromAddress, recipientAddress: toAddress }
                if (data?.createdDate) {
                    const createdDate = dayjs(data.createdDate, 'YYYY-MM-DD HH:mm A');
                    if (createdDate) {
                        data = { ...data, 'createdDate': createdDate.format('YYYY-MMM-DD hh:mm A') }
                    }
                }
                this.setState({
                    data: data,
                    isDataFetching: false,
                })
            })
            .catch(() => { })
    }
    fetchOrderEvents = async () => {
        await ApiHandler({ url: Api.order_get_events, method: HTTP_METHOD.GET, requestParams: { orderId: this.props.params.id } })
            .then(response => {
                let currentStep = 0
                let status = PENDING_PICK_UP
                let timeMap = {}
                let steps = []
                let pending_reschedule_comment = null
                response?.map((orderEvent, index) => {
                    status = orderEvent.orderStatus
                    let formatedEventTime = ''
                    if (orderEvent.timestamp) {
                        const eventTime = dayjs(orderEvent.timestamp, 'YYYY-MM-DD HH:mm:ss');
                        if (eventTime) {
                            timeMap[orderEvent.orderStatus] = eventTime.format('YYYY-MMM-DD hh:mm A')
                            formatedEventTime = eventTime.format('YYYY-MMM-DD hh:mm A')
                        }
                    }
                    if (status === CANCELLED) {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent.status} date={formatedEventTime} event={orderEvent} />,
                                icon: <SvgOrderIncomplete width={25} height={25} color={'red'} />
                            }
                        )
                    } else {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent.status} date={formatedEventTime} event={orderEvent} />,
                                // icon: <SvgCheck width={25} height={25} color={'green'} />
                                icon: <div style={{ width: 20, height: 20, backgroundColor: 'green' }}></div>
                            }
                        )
                    }
                    if (orderEvent.orderStatus === 'PENDING_RESCHEDULE') {
                        pending_reschedule_comment = orderEvent.comments

                    }
                    currentStep = index + 1
                })
                this.setState({
                    pending_reschedule_comment,
                    isDataFetching: false,
                    orderEvent: response ? response : [],
                    currentStep: currentStep,
                    orderSteps: steps
                })
            })
            .catch(() => { })

    }
    cancelOrder = async (tracking_number) => {
        this.setState({
            isCancelling: true
        })
        try {
            await ApiHandler({ url: Api.order, method: HTTP_METHOD.DELETE, mediaType: MEDIA_TYPE.JSON, specificId: tracking_number, customMessage: "Your Parcel has been cancelled." })
        } catch (error) {
        }
        this.setState({
            showOrderCancelConfirmModal: false,
            isCancelling: false
        })

        this.fetchData()
    }

    searchTrackingNumber = async () => {
        const { data } = this.state
        this.setState({ confirming_tracking_number: true })
        try {
            const requestParams = {
                trackingNumber: data.trackingNumber
            }
            const response = await ApiHandler({ url: Api.confirm_rom_order, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, requestParams })
            if (response) {
                this.props.navigate(
                    CustomPath.rom_order,
                    { state: { data: response } }
                );
            }
        } catch (error) {
            message.error("Can not request this time")
        }
        this.setState({ confirming_tracking_number: false })
    }

    render() {
        const { data, isDataFetching, currentStep, orderSteps, showOrderCancelConfirmModal, orderEvent, isCancelling, confirming_tracking_number, order_object, pending_reschedule_comment } = this.state

        const customSizeStyles = {
            width: containerW,
            height: containerH,
        };

        return (
            <>
                <Modal
                    title={"Confirmation of Parcel Cancellation"}
                    centered
                    open={showOrderCancelConfirmModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <p>Are you sure you want to cancel this order?</p>
                    <Button type='primary' loading={isCancelling} onClick={() => this.cancelOrder(data.trackingNumber)}>Cancel Now</Button>
                </Modal>


                <Row>
                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={6} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: <Link to={CustomPath.order_history}>Parcel History</Link>
                                        },
                                        {
                                            title: 'Parcel Detail'
                                        }

                                    ]}
                                />
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                                <Link to={order_object?.route} relative="path">
                                    <Button type="primary">Back</Button>
                                </Link>
                            </Col>
                        </Row>

                        <NJVSpacer height={20} />
                        {
                            isDataFetching ?
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Skeleton active />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <div>
                                                {/* <Row>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVLabel label="Tracking Number" />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label="Pickup Address" />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.name} `} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <NJVSpacer height={20} /> */}
                                                <Row gutter={[16, 16]}>
                                                    <Col span={24}>
                                                        {
                                                            data.orderStatus === 'CANCELLED' ?
                                                                <>
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <Card
                                                                                style={{ backgroundColor: "red", boxShadow: 'none' }}
                                                                                bordered={false}>
                                                                                <span style={{ fontSize: 13, fontWeight: '600', color: 'white' }}>This parcel has been cancelled</span>
                                                                            </Card>
                                                                        </Col>
                                                                    </Row>
                                                                    <NJVSpacer height={20} />
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVLabel label="Tracking Number" />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label="Pickup Address" />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship.division.name}`} />

                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            {
                                                                data?.shipper?.userType === 'SP_STAFF' ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label="Store Name" />
                                                                        <NJVValue value={data?.shipper?.fullName} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVLabel label="Sender Name" />
                                                            <NJVValue value={`${data.senderName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Sender Phone Number" />
                                                            <NJVValue value={data.senderPhoneNumber} />


                                                            {
                                                                data.additionalAddress ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label="Sender Address" />
                                                                        <NJVValue value={data.additionalAddress} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVLabel label="Receiver Name" />
                                                            <NJVValue value={`${data.recipientName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Receiver Phone Number" />
                                                            <NJVValue value={data.recipientPhoneNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.toAddress && data.toTownship ?
                                                                    <>
                                                                        <NJVLabel label="Receiver Address" />
                                                                        <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </Card>
                                                    </Col>
                                                    <Col span={24}>
                                                        <Card
                                                            style={{ backgroundColor: "#f5f5f5", boxShadow: 'none' }}
                                                            bordered={false}>
                                                            <NJVSpacer height={30} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Pickup Time" />
                                                            <NJVValue value={`${data.pickupDate}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="OrderType" />
                                                            <NJVValue value={`${data.orderType}`} />
                                                            {
                                                                data?.paymentOption ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label="Payment Option" />
                                                                        <NJVValue value={`${data.paymentOption}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="COD Amount" />
                                                            <NJVValue value={`${data.codAmount ? data.codAmount : 0} MMK`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label="Note" />
                                                            <NJVValue value={`${data.note ? data.note : 'No not here'}`} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data?.updateDeliveryCharges ?
                                                                    <>
                                                                        <NJVLabel label="Updated Delivery Charges" />
                                                                        <NJVValue value={`${data.updateDeliveryCharges}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }
                                                            {
                                                                data?.updateCodAmount ?
                                                                    <>
                                                                        <NJVLabel label="Updated Cod Amount" />
                                                                        <NJVValue value={`${data.updateCodAmount}`} />
                                                                        <NJVSpacer height={30} />
                                                                    </> :
                                                                    <></>
                                                            }

                                                            {
                                                                orderSteps?.length !== 0 ?
                                                                    <>
                                                                        <NJVLabel label="Status" />
                                                                        <NJVSpacer height={10} />
                                                                        {/* <Steps
                                                                            className="step-full-width-title"
                                                                            size="small"
                                                                            current={currentStep}
                                                                            direction="vertical"
                                                                            items={orderSteps}
                                                                        /> */}
                                                                        <ParcelEventSteps events={orderEvent} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            {
                                                                pending_reschedule_comment ?
                                                                    <>
                                                                        <NJVSpacer height={30} />
                                                                        <NJVLabel label="Fail Reason" />
                                                                        <NJVValue value={pending_reschedule_comment} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }

                                                            <NJVSpacer height={30} />
                                                            <div style={{ display: 'flex' }}>
                                                                <di style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="Product Image" />
                                                                    {
                                                                        data.productImageUrl ?

                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }
                                                                </di>
                                                                <div>
                                                                    <NJVLabel label="Package Image" />
                                                                    {
                                                                        data.packageImageUrl ?
                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.packageImageUrl}
                                                                                // preview={false}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }


                                                                </div>
                                                            </div>
                                                            <br />
                                                            {
                                                                data.senderNRCFrontImageUrl ?
                                                                    <>
                                                                        <NJVLabel label="Sender's NRC" />
                                                                        <Image
                                                                            style={{
                                                                                marginTop: 5,
                                                                                borderRadius: 9,
                                                                                cursor: 'pointer'
                                                                            }}
                                                                            onClick={() => this.setState({ viewPhoto: true })}
                                                                            src={data.senderNRCFrontImageUrl}
                                                                            // preview={false}
                                                                            width={200}
                                                                        />
                                                                    </>

                                                                    : <></>
                                                            }


                                                            <br />
                                                            {
                                                                data?.shipper?.fullName && data?.shipper?.userType === 'SP_STAFF' ?
                                                                    <>
                                                                        <br />
                                                                        <NJVLabel label={`Created By : ${data?.shipper?.fullName}`} />
                                                                    </>

                                                                    : <></>
                                                            }

                                                        </Card>
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginBottom: 100 }}>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ height: 20 }}>

                                                            <div
                                                                key={2}
                                                                ref={(el) => this.refComponent = el}
                                                                style={customSizeStyles}>
                                                                <AirWayBillSlip
                                                                    key={1}
                                                                    senderName={data.senderName}
                                                                    senderPhone={data.senderPhoneNumber}
                                                                    senderAddress={data.senderAddress}
                                                                    receiverName={data.recipientName}
                                                                    receiverPhone={data.recipientPhoneNumber}
                                                                    receiverAddress={data.recipientAddress}
                                                                    trackId={data.trackingNumber}
                                                                    createDate={data.createdDate}
                                                                    parcelAmount={data.parcelAmount}
                                                                    deliveryCharges={data?.updateDeliveryCharges ? data?.updateDeliveryCharges : data.deliveryCharges}
                                                                    cod={data?.updateCodAmount ? data?.updateCodAmount : data.codAmount}
                                                                    note={data.note}
                                                                    sortingCode={data.sortingCode}
                                                                />
                                                            </div>
                                                            <div style={{ textAlign: 'center', marginBottom: 50 }}>
                                                                <ReactToPrint
                                                                    trigger={() => <Button type="primary" style={{ backgroundColor: Colors.primary, width: 150 }}>Print</Button>}
                                                                    content={() => this.refComponent}
                                                                />
                                                                <br />

                                                                {
                                                                    data.orderType === 'Home Delivery' && (data.orderStatus !== 'DELIVERED' || data.orderStatus !== 'CANCELED') ?
                                                                        <>
                                                                            <NJVSpacer height={50} />
                                                                            <Button type="text" onClick={() => this.searchTrackingNumber()}>
                                                                                <span style={{ color: 'blue', fontWeight: '600' }} loading={confirming_tracking_number}>Request Parcel Exchange</span>
                                                                            </Button>
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                                <br />

                                                                {
                                                                    data.orderStatus === 'PENDING_PICK_UP' || data.orderStatus === 'PICK_UP_EXCEPTION' ?
                                                                        <>
                                                                            <NJVSpacer height={50} />
                                                                            <Button type="text" onClick={() => this.setState({ showOrderCancelConfirmModal: true })}>
                                                                                <span style={{ color: 'red', fontWeight: '600' }}>Cancel Parcel</span>
                                                                            </Button>
                                                                            <NJVSpacer height={50} />
                                                                            <br />
                                                                            <br />
                                                                        </>
                                                                        :
                                                                        <>
                                                                        </>
                                                                }
                                                            </div>

                                                        </div>
                                                    </Col>
                                                    <NJVSpacer height={400} />
                                                </Row>

                                            </div>
                                            :
                                            <></>
                                    }
                                </>
                        }
                    </Col>
                </Row >
            </>
        )
    }

}

export default compose(withRouter)(OrderDetail)